export enum 文件类型 {
    图片 = 1,
}

export enum 佣金记录_审核状态 {
    待计算 = 0,
    待领取 = 1,
    待审核 = 2,
    已领取 = 3,
    审核未通过 = 4,
}

export enum 资金管理_审核入款_审核状态 {
    待审核 = 0,
    锁定 = 1,
    待支付 = 2,
    拒绝入款 = 4,
    订单成功 = 5,
    订单失败 = 6,
}

export enum 运营管理_审核状态 {
    待审核 = 0,
    已通过 = 1,
    未通过 = 2,
}

export enum 平台游戏_字段值 {
    维护 = 1,
    推荐 = 2,
    启用 = 3,
    禁用 = 3,
}

export enum 支付日志查询_订单状态 {
    成功 = 5,
    失败 = 6,
}

export enum 支付设置_线下支付类型 {
    银行卡 = "UNION_PAY",
    USDT = "USDT_PAY",
}

export enum 任务系统_任务类型 {
    游戏 = 0,
    充值 = 1,
    代理 = 2,
    下载 = 3,
}

export enum 活动列表_活动类型 {
    普通活动 = 0,
    转盘活动 = 1,
    红包雨 = 2,
    积分转盘活动 = 3,
    自定义活动 = 4,
    推广转盘 = 5,
    新手存款活动=6,
}

export enum 红包任务_红包状态 {
    未开始 = 0,
    进行中 = 1,
    已结束 = 2,
}

export enum 彩金状态 {
    未开始 = 0,
    进行中 = 1,
    已结束 = 2,
}

export enum 审核出款_审核状态 {
    待审核 = 0,
    // 审核中
    // 待审核锁定中 = 1,
    // 待出款 = 2,
    // 处理中
    出款锁定中 = 1,
    待支付 = 2,
    渠道支付失败 = 3,
    // 出款中 = 4,
    拒绝出款 = 4,
    // 出款成功
    订单成功 = 5,
    // 取消
    订单失败 = 6,
}

export enum 审核出款_提款方式 {
    银行卡 = 1,
    数字货币 = 2,
    三方钱包 = 3,
}

export enum 审核出款_会员提款方式 {
    支付宝支付 = "ALI_PAY",
    银联支付 = "UNION_PAY",
    UPAY支付 = "UPAY_PAY",
    USDT支付 = "USDT_PAY",
    虚拟币支付 = "WALLET_PAY",
    微信支付 = "WECHAT_PAY",
}

export enum 审核出款_渠道支付类型 {
    支付宝支付 = "ALI_PAY",
    微信支付 = "WECHAT_PAY",
    TOPAY支付 = "TO_PAY",
    银联支付 = "UNION_PAY",
    USDT支付 = "USDT_PAY",
    OKPAY支付 = "OK_PAY",
    GOPAY支付 = "GO_PAY",
    UPAY支付 = "UPAY_PAY",
    数字人民币 = "DIGITAL_RMB_PAY",
}
export enum 出款类型 {
    人工出款 = 0,
    代付出款 = 1,
}
export enum 审核出款_支付类型 {
    人工 = 0,
    代付 = 1,
}

export enum 红包类型 {
    随机红包 = 1,
    固定红包 = 2,
}

export enum 流水限制类型 {
    无流水限制 = 1,
    流水金额限制 = 2,
    流水倍数限制 = 3,
}

export enum 调整类型 {
    账户调整 = 1,
    后台充值 = 2,
    后台提现 = 3,
    返水调整 = 4,
    红利调整 = 5,
    赠送彩金 = 6,
}

export enum 上下分类型 {
    上分 = 1,
    下分 = 2,
}

export enum 发红包类型 {
    随机红包 = 1,
    指定红包 = 2,
    全量红包 = 3,
}

export enum 有效投注类型 {
    历史累计有效投注 = "0",
    当日有效投注 = "1",
}

export enum 存款条件 {
    当日存款 = "0",
    历史累计存款 = "1",
}

export enum 抽奖条件 {
    按VIP等级 = "0",
    指定会员 = "1",
    无限制 = "2",
    按会员层级 = "3",
}

export enum 转盘活动_奖品类型 {
    礼金 = "0",
    奖品 = "1",
    空白奖 = "2",
}

export enum 出入款设置 {
    通用模板ID = "DEFAULT_WITHDRAW_ID",
    通用出款类型 = "DEFAULT_WITHDRAW_ID",
}

export enum 账户调整_审核状态 {
    等待 = "0",
    锁定 = "-1",
    通过 = "1",
    拒绝 = "2",
}

export enum 账户调整_历史记录_状态 {
    通过 = "1",
    拒绝 = "2",
}

export enum 稽核类型 {
    会员充值 = "1",
    活动 = "2",
    任务 = "3",
    账户调整 = "4",
    后台充值 = "5",
    返水调整 = "6",
    红利调整 = "7",
    返水 = "8",
    返佣 = "9",
    VIP奖励 = "10",
}

export enum 离线包管理_状态 {
    未启用 = 0,
    定时启用中 = -1,
    启用中 = 1,
}

export enum 离线包管理_启用方式 {
    立即启用 = 1,
    定时启用 = -1,
}

export enum 任务列表_任务周期 {
    永久 = 0,
    限时 = 1,
}

export enum 任务列表_任务首页弹框提示 {
    不提示 = 0,
    提示 = 1,
}

export enum 任务列表_任务数据类型 {
    打码额 = 0,
    盈利额 = 1,
    亏损额 = 2,
}

export enum 账户调整_赠送对象 {
    指定会员 = 0,
    批量会员 = 1,
}

export enum 支付通道_费率类型 {
    每笔 = 1,
    费率 = 2,
}

export enum 资金报表_项目类型 {
    活动优惠 = 1,
    红包 = 2,
    签到彩金 = 3,
    排行版彩金 = 4,
    预送彩金 = 5,
    周礼金 = 6,
    月礼金 = 7,
    晋级彩金 = 8,
    赠送彩金 = 9,
    红利调整 = 10,
    任务彩金 = 11,
    充值优惠 = 12,
    提现优惠 = 13,
    洗码彩金 = 14,
    洗码调整 = 15,
    第三方充值 = 16,
    公司入款 = 17,
    人工充值 = 18,
    会员取款 = 19,
    人工取款 = 20,
    佣金详情 = 21,
    充值手续费 = 22,
    提现手续费 = 23,
    提现超额手续费 = 24,
    账户调整 = 25,
}

export enum 资金报表_类型 {
    充值报表 = 1,
    提现报表 = 2,
    优惠报表 = 3,
    洗码报表 = 4,
    佣金详情 = 5,
    手续费报表 = 6,
    其他报表 = 7,
}

export enum 游戏分类 {
    真人 = 1,
    电子 = 2,
    彩票 = 3,
    体育 = 4,
    电竞 = 5,
    捕鱼 = 6,
    棋牌 = 7,
}

export enum 站内信息_指定会员类型 {
    全部会员 = 0,
    指定会员 = 1,
    批量用户 = 2,
}

export enum 红包活动_红包领取状态 {
    未领取 = 0,
    已领取 = 2,
}

// TODO 需要重新对接
export enum 风控策略_类型 {
    风控标签 = 1,
    上分类型特殊 = 2,
    多账号 = 3,
    前几次提款 = 4,
    新会员大额提款 = 5,
    高盈利倍数 = 6,
    新会员首次活动提款 = 7,
    改名提款 = 8,
    发放红利 = 9,
    提款次数过多 = 10,
    会员大额盈利率 = 11,
}

export enum 注册限制类型 {
    不限制 = 0,
    每天 = 1,
    每月 = 2,
    永久 = 3,
}
